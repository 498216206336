import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unshift.js";
import Nav from '@/components/Nav';
export default {
  data: function data() {
    return {
      swiperIndex: 1,
      swiperList: [require('@/assets/images/index2/swiper-1.png'), require('@/assets/images/index2/swiper-2.png'), require('@/assets/images/index2/swiper-3.png'), require('@/assets/images/index2/swiper-4.png'), require('@/assets/images/index2/swiper-5.png')],
      swiperClass: [],
      timer: null,
      minHeight: null,
      paddingBottom: null,
      bottom: null
    };
  },
  components: {
    Nav: Nav
  },
  computed: {},
  methods: {
    initSwiper: function initSwiper() {
      var _this = this;
      var index = 5;
      var loop = function loop() {
        if (index > 0) {
          setTimeout(function () {
            _this.swiperClass.push(index);
            index--;
            loop();
          }, 200);
        }
      };
      loop();
      this.timer = setInterval(function () {
        var last = _this.swiperClass[4];
        _this.swiperClass.length = 4;
        _this.swiperClass.unshift(last);
      }, 2500 + 800);
    }
  },
  mounted: function mounted() {
    this.initSwiper();
  },
  created: function created() {
    //原生浏览器底部状态栏可能会挡住页面
    var clientHeight = document.body.clientHeight || 0;
    var innerHeight = window.innerHeight || 0;
    var diff = clientHeight - innerHeight;
    //超过合理区间重置
    var height = diff < 50 && diff > -50 ? diff : 0;
    this.minHeight = "calc(100vh - ".concat(50 + height, "px)");
    this.paddingBottom = "".concat(50 + height, "px");
    this.bottom = "".concat(height + 17, "px");
  },
  beforeDestroy: function beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  }
};