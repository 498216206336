import axios from "axios";
import { Toast } from 'vant'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
    console.log(config.url)
    if (!config.url.includes('/cgm/cmsContent/getOfficialList')) {
        config.headers['appCode'] = '32';
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 0;
    // 获取错误信息
    if (code !== 0) {
        Toast(res.data.msg)
    }
    return res.data
},
    error => {
        // Toast('网络异常，请稍后再试')
        return Promise.reject(error)
    }
)

export default service