import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.scss'
import 'amfe-flexible' // rem 布局适配
var VueTouch = require('vue-touch')
import { Toast } from 'vant';
import 'vant/es/toast/style';

import { randomString } from '@/utils/utils'
localStorage.setItem('subKey', randomString(11))

const app = createApp(App)
app.use(Toast)
app.use((VueTouch, { name: 'v-touch' })).use(store).use(router).mount('#app')
