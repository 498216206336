import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1b31401c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "img"
};
var _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: $props.src,
    alt: "",
    style: _normalizeStyle({
      width: $data.width,
      height: $data.height
    })
  }, null, 12, _hoisted_2)]);
}