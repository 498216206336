import _objectSpread from "C:/Users/hujun/Desktop/front-end/official-mobile/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.index-of.js";
var MAXPAGE = 3;
var MINPAGE = 1;
var windowHeight = document.body.clientHeight;
import Page1 from './switch/page1.vue';
import Page2 from './switch/page2.vue';
import Page3 from './switch/page3.vue';
import { mapState, mapMutations } from 'vuex';
export default {
  data: function data() {
    return {
      startX: null,
      startY: null,
      // activePageIndex: 1,
      transform: '',
      transformTime: 0.8,
      pageKey: [0, 1, 2],
      floatButtonTop: [213, 427, 363]
    };
  },
  components: {
    Page1: Page1,
    Page2: Page2,
    Page3: Page3
  },
  watch: {
    activePageIndex: {
      handler: function handler(value) {
        //初始化元素移动
        //   this.$refs[`Page${ value }`].initTransform()
        this.pageKey[value - 1] = this.getRangeCode();
        this.transform = "translateY(".concat((value - 1) * -100, "vh)");
      },
      immediate: true
    }
  },
  computed: _objectSpread({}, mapState(['activePageIndex'])),
  methods: _objectSpread(_objectSpread({}, mapMutations(['SWITCH_ACTIVE_PAGE_INDEX'])), {}, {
    touchstart: function touchstart(e) {
      if (this.dragLock) return;
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchmove: function touchmove(e) {
      this.isWeixin ? this.handlerDrag(e) : null;
    },
    touchend: function touchend(e) {
      !this.isWeixin ? this.handlerDrag(e) : null;
    },
    handlerDrag: function handlerDrag(e) {
      if (this.dragLock) return;
      this.clientHeight = document.documentElement.clientHeight; //浏览器高度
      this.scrollHeight = document.querySelector("#container".concat(this.activePageIndex)).scrollHeight; //page123页面包裹的文档实际高度
      this.scrollTop = document.querySelector("#page".concat(this.activePageIndex)).scrollTop || document.documentElement.scrollTop || document.body.scrollTop; //获取能获取的任意被卷去高度
      this.moveX = e.changedTouches[0].clientX;
      this.moveY = e.changedTouches[0].clientY;
      if (this.scrollTop <= 0 && this.startY - this.moveY <= -30) {
        // 下拉触发
        this.dragDown();
        this.lock();
      }
      if (this.startY - this.moveY >= 100 && this.scrollTop >= this.scrollHeight - this.clientHeight - 200) {
        // 上划触发/有些网页操作栏高度没有算在scrollTop里，减去200
        this.dragUp();
        this.lock();
      }
    },
    // touchcancel(e) {
    //   alert('touchcancel')
    // },
    dragUp: function dragUp() {
      if (this.activePageIndex >= MAXPAGE) return;
      this.switchName = 'switch-up';
      this.SWITCH_ACTIVE_PAGE_INDEX(this.activePageIndex + 1);
    },
    dragDown: function dragDown() {
      if (this.activePageIndex <= MINPAGE) return;
      this.switchName = 'switch-down';
      this.SWITCH_ACTIVE_PAGE_INDEX(this.activePageIndex - 1);
    },
    lock: function lock() {
      var _this = this;
      this.dragLock = true;
      setTimeout(function () {
        _this.dragLock = false;
      }, 1000);
    },
    switchPage: function switchPage(data) {
      this.SWITCH_ACTIVE_PAGE_INDEX(data);
    },
    getRangeCode: function getRangeCode() {
      var len = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 6;
      var orgStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var returnStr = "";
      for (var i = 0; i < len; i++) {
        returnStr += orgStr.charAt(Math.floor(Math.random() * orgStr.length));
      }
      return returnStr;
    },
    //跳转微信客服
    toService: function toService() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc9d35f8f6f924c917';
    }
  }),
  // mounted() {
  //   console.log('mounted')
  // },
  mounted: function mounted() {
    var ua = navigator.userAgent.toLowerCase();
    this.isWeixin = ua.indexOf('micromessenger') != -1;
  }
};