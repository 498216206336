import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import TimeLine from '@/components/TimeLine';
export default {
  data: function data() {
    return {
      swiperIndex: 1,
      swiperList: [require('@/assets/images/about/develop-swiper-1.png'), require('@/assets/images/about/develop-swiper-2.png'), require('@/assets/images/about/develop-swiper-3.png'), require('@/assets/images/about/develop-swiper-4.png'), require('@/assets/images/about/develop-swiper-5.png'), require('@/assets/images/about/develop-swiper-6.png'), require('@/assets/images/about/develop-swiper-7.png')],
      swiperClass: [1, 2, 3, 4, 5, 6, 7],
      activeNav: '',
      timer: null
    };
  },
  components: {
    TimeLine: TimeLine
  },
  computed: {},
  methods: {
    initSwiper: function initSwiper() {
      var _this = this;
      this.timer = setInterval(function () {
        var first = _this.swiperClass[0];
        _this.swiperClass.splice(0, 1);
        _this.swiperClass.push(first);
      }, 2500);
    }
  },
  mounted: function mounted() {
    this.initSwiper();
  },
  beforeDestroy: function beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  }
};