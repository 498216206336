import "core-js/modules/es.function.name.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import { secondRoutes } from '@/router';
export default {
  data: function data() {
    return {
      navList: secondRoutes.children,
      activeNav: '',
      activeChild: ''
    };
  },
  methods: {
    navClick: function navClick(name) {
      if (this.activeNav == name) {
        this.activeNav = '';
      } else {
        this.activeNav = name;
      }
    },
    childClick: function childClick(child) {
      var _this = this;
      this.activeChild = child.name;
      setTimeout(function () {
        _this.$router.push(child.path);
      }, 500);
    }
  }
};