import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3db3a702"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "news"
};
var _hoisted_2 = {
  "class": "list"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  "class": "cover"
};
var _hoisted_5 = {
  "class": "context"
};
var _hoisted_6 = {
  "class": "number"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Image = _resolveComponent("Image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      "class": "item",
      onClick: function onClick($event) {
        return $options.toDetail(item.redirectUrl);
      },
      key: index
    }, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_Image, {
      src: item.thumb
    }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_6, " 阅读  " + _toDisplayString(item.hits), 1)], 8, _hoisted_3);
  }), 128))])]);
}