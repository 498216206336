import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '@/views/index.vue'
import Second from '@/views/second.vue'
import Introduction from '@/views/about/introduction.vue'
import Develop from '../views/about/develop.vue'
import Join from '../views/about/join.vue'
import News from '../views/about/news.vue'
import Document from '@/views/research/document.vue'
import Case from '@/views/research/case.vue'
import Test from '@/views/consult/test.vue'
import Branch from '@/views/consult/branch.vue'
import Hospital from '@/views/consult/hospital.vue'
import Market from '@/views/consult/market.vue'

let routes = [
  {
    path: '/index',
    component: Index,
    name: 'Index',
    meta: {
      title: '首页'
    }
  },
  { path: '/', redirect: '/index' },
]

export const secondRoutes = {
  path: '/second',
  component: Second,
  children: [
    {
      path: '/about',
      name: 'About',
      meta: {
        title: '关于诺特',
        titlePic: require('../assets/images/about/title.png')
      },
      children: [
        {
          path: '/about/join',
          component: Join,
          name: 'Join',
          meta: {
            title: '加入我们',
            banner: require('../assets/images/about/banner-join.png')
          }
        },
        {
          path: '/about/introduction',
          component: Introduction,
          name: 'Introduction',
          meta: {
            title: '品牌介绍',
            banner: require('../assets/images/about/banner-introduction.png')
          }
        },
        {
          path: '/about/develop',
          component: Develop,
          name: 'Develop',
          meta: {
            title: '发展历程',
            banner: require('../assets/images/about/banner-develop.png')
          }
        },
        {
          path: '/about/news',
          component: News,
          name: 'News',
          meta: {
            title: '新闻动态',
            banner: require('../assets/images/about/banner-news.png')
          }
        },
      ]
    },
    {
      path: '/research',
      name: 'Research',
      meta: {
        title: '案例&科研',
        titlePic: require('../assets/images/research/title.png')
      },
      children: [
        {
          path: '/research/case',
          component: Case,
          name: 'Case',
          meta: {
            title: '成功案例',
            banner: require('../assets/images/research/banner-case.png')
          }
        },
        {
          path: '/research/document',
          component: Document,
          name: 'Document',
          meta: {
            title: '科研成果',
            banner: require('../assets/images/research/banner-document.png')
          }
        }
      ]
    },
    {
      path: '/consult',
      name: 'Consult',
      meta: {
        title: '服务中心',
        titlePic: require('../assets/images/consult/title.png')
      },
      children: [
        {
          path: '/consult/test',
          component: Test,
          name: 'Test',
          meta: {
            title: '科学检测',
            banner: require('../assets/images/consult/banner-test-1.png')
          }
        },
        {
          path: '/consult/branch',
          component: Branch,
          name: 'Branch',
          meta: {
            title: '全国分中心',
            banner: require('../assets/images/consult/banner-branch.png')
          }
        },
        {
          path: '/consult/hospital',
          component: Hospital,
          name: 'Hospital',
          meta: {
            title: '互联网医院',
            banner: require('../assets/images/consult/banner-hospital.png')
          }
        },
        {
          path: '/consult/market',
          component: Market,
          name: 'Market',
          meta: {
            title: '健康商城',
            banner: require('../assets/images/consult/banner-market.png')
          }
        },
      ]
    }
  ]

}

routes = [...routes, secondRoutes]
console.log(routes)
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
