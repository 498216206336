import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0f860dac"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "case"
};
var _hoisted_2 = {
  "class": "nav-list"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  "class": "content"
};
var _hoisted_5 = ["onClick"];
var _hoisted_6 = {
  "class": "cover"
};
var _hoisted_7 = {
  "class": "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Image = _resolveComponent("Image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navList, function (item) {
    return _openBlock(), _createElementBlock("p", {
      "class": _normalizeClass(["nav-item", {
        'active-item': item.catId === $data.activeItem
      }]),
      onClick: function onClick($event) {
        return $options.changeTab(item);
      },
      key: item.index
    }, _toDisplayString(item.catName), 11, _hoisted_3);
  }), 128))]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.caseList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      "class": "article",
      onClick: function onClick($event) {
        return $options.redirect(item.redirectUrl);
      },
      key: item.index
    }, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_Image, {
      src: item.thumb,
      alt: ""
    }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.title), 1)], 8, _hoisted_5);
  }), 128))])]);
}