import "core-js/modules/web.timers.js";
import Nav from '@/components/Nav';
export default {
  components: {
    Nav: Nav
  },
  data: function data() {
    return {
      distance1: '',
      distance2: '',
      transition: 'none',
      showOpenPage: '',
      pageIndex: 1
    };
  },
  methods: {
    initTransform: function initTransform() {
      var _this = this;
      this.start();
      setTimeout(function () {
        _this.end();
      }, 500);
    },
    start: function start() {
      this.distance1 = '400px';
      this.distance2 = '600px';
      this.transition = 'none';
    },
    end: function end() {
      this.transition = 'all 1s ease-in-out';
      this.distance1 = this.distance2 = 0;
    },
    openPage: function openPage(index) {
      this.showOpenPage = true;
      this.pageIndex = index;
    },
    closePage: function closePage() {
      this.showOpenPage = false;
    }
  },
  mounted: function mounted() {
    this.initTransform();
  }
};