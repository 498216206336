import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9a841362"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "nav tap-clear"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass(["nav-item", {
        'nav-item-active': $data.activeNav === item.name
      }]),
      key: index,
      onClick: _withModifiers(function ($event) {
        return $options.navClick(item.name);
      }, ["stop"])
    }, [_createElementVNode("span", null, _toDisplayString(item.meta.title), 1), _createElementVNode("div", {
      "class": _normalizeClass(["nav-item-children", {
        'transitionItem': $data.activeNav === item.name
      }]),
      style: _normalizeStyle({
        height: $data.activeNav === item.name ? "".concat(40 * item.children.length / 3.75, "vw") : '0'
      })
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, function (child, childIndex) {
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass(["nav-item-child", {
          'nav-item-child-active': $data.activeChild === child.name
        }]),
        key: childIndex,
        onClick: _withModifiers(function ($event) {
          return $options.childClick(child);
        }, ["stop"])
      }, _toDisplayString(child.meta.title), 11, _hoisted_3);
    }), 128))], 6)], 10, _hoisted_2);
  }), 128))]);
}