import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-461bdd4a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "page"
};
var _hoisted_2 = {
  "class": "swiper"
};
var _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_TimeLine = _resolveComponent("TimeLine");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.swiperList, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      "class": _normalizeClass(["item bottom", "item-".concat($data.swiperClass[index])]),
      key: index
    }, [_createElementVNode("img", {
      src: item,
      alt: ""
    }, null, 8, _hoisted_3)], 2);
  }), 128))]), _createVNode(_component_TimeLine)]);
}