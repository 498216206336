export const randomString = (e) => {
    e = e || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n
}

export const fileViewer = function (url) {
    const fileTypeList = ['.pdf', '.doc', '.docx', '.xls', '.xlsx']
    const fileType = fileTypeList.find(item => url.indexOf(item) !== -1)
    console.log(fileType)
    if (fileType == '.pdf') {//pdf文件
        let fileUrl = encodeURIComponent(url)
        window.open("https://m.nutriease.com/pdfjs/web/viewer.html?file=" + fileUrl);
    } else if (!fileType) {//其他文件
        window.open(url);
    } else {//office文件
        const fileUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(url);
        window.open(fileUrl);
    }
}
