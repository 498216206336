import ChildTest from './components/childTest';
import SelfTest from './components/selfTest';
export default {
  data: function data() {
    return {
      navList: [{
        name: '一分钟自测',
        banner: require('@/assets/images/consult/banner-test-1.png')
      }, {
        name: '少儿肥胖测评',
        banner: require('@/assets/images/consult/banner-test-2.png')
      }, {
        name: '了解诺特减重方案',
        banner: require('@/assets/images/consult/banner-test-3.png')
      }],
      activeItem: {
        name: '一分钟自测',
        banner: require('@/assets/images/consult/banner-test-1.png')
      }
    };
  },
  components: {
    ChildTest: ChildTest,
    SelfTest: SelfTest
  },
  watch: {
    activeItem: function activeItem(value) {
      this.$emit('setBanner', value.banner);
    }
  },
  methods: {},
  mounted: function mounted() {}
};